import React, { Fragment, useCallback, useMemo } from 'react'
import useAuth from '@hooks/useAuth'
import SEO from '@components/SEO'
import getURLSearchParams from '@utils/getURLSearchParams'
import Redirect from '@components/encapsulators/Redirect'
import { useFetch } from '@wicadu/arepa/hooks'
import { isBrowser } from '@wicadu/arepa/utils'
import { navigate } from 'gatsby'

function CheckUsername () {
  const { accessToken } = useAuth()

  const username = useMemo(() => {
    const urlParams: URLSearchParams = getURLSearchParams()

    return {
      usernameKey: urlParams.get('key'),
      usernameValue: urlParams.get('value')
    }
  }, [])

  const url: string = useMemo(() => {
    if (!isBrowser()) return ''

    let url: URL = new URL(`${process.env.OAUTH_SERVER_HOST}/api/users/who-does-it-belong-to`)

    url.search = new URLSearchParams(username).toString()

    return url.toString()
  }, [
    username.usernameKey,
    username.usernameValue,
  ])

  const onCompleted = useCallback(() => {
    navigate('/login', {
      state: {
        ...username
      }
    })
  }, [
    username.usernameKey,
    username.usernameValue,
  ])

  const onError = useCallback((err: Error) => {
    if (err.message.match('It wasn\'t found'))
      navigate('/signup', {
        state: {
          ...username
        }
      })
    else
      navigate('/500')
  }, [
    username
  ])

  useFetch(
    url, {
    fetchOptions: {
      headers: isBrowser() && new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
    },
    onCompleted,
    onError,
    skip: !Boolean(url)
  })

  if (Boolean(accessToken)) return <Redirect />

  return (
    <Fragment>
      <SEO title='Checking' />
    </Fragment>
  )
}

export default CheckUsername
